import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icons',
  standalone: true,

  templateUrl: './icons.component.html'
})
export class IconsComponent {
  @Input() icons = 'search';
  @Input() width = '24';
  @Input() height = '24';
  @Input() fill!: string;
  @Input() stroke!: string;
  @Input() fillInner!: string;
}
